define("discourse/plugins/discourse-events/discourse/controllers/events-confirm-event-deletion", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/models/event", "I18n"], function (_exports, _controller, _modalFunctionality, _decorators, _event, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELETE_TARGETS = ["events_only", "events_and_topics", "topics_only"];
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    deleteTargets: DELETE_TARGETS.map(t => ({
      id: t,
      name: _I18n.default.t(`admin.events.event.delete.${t}`)
    })),
    deleteTarget: "events_only",
    eventCount(events) {
      return events.length;
    },
    btnLabel(deleteTarget) {
      return `admin.events.event.delete.${deleteTarget}_btn`;
    },
    actions: {
      delete() {
        const events = this.model.events;
        const eventIds = events.map(e => e.id);
        const target = this.deleteTarget;
        const opts = {
          event_ids: eventIds,
          target
        };
        this.set("destroying", true);
        _event.default.destroy(opts).then(result => {
          if (result.success) {
            this.onDestroyEvents(events.filter(e => result.destroyed_event_ids.includes(e.id)), events.filter(e => result.destroyed_topics_event_ids.includes(e.id)));
            this.onCloseModal();
            this.send("closeModal");
          } else {
            this.set("model.error", result.error);
          }
        }).finally(() => this.set("destroying", false));
      },
      cancel() {
        this.onCloseModal();
        this.send("closeModal");
      }
    }
  }, [["method", "eventCount", [(0, _decorators.default)("model.events")]], ["method", "btnLabel", [(0, _decorators.default)("deleteTarget")]]]));
});