define("discourse/plugins/discourse-events/discourse/controllers/event-rsvp", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/get-owner", "discourse/lib/ajax", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "@ember/controller", "@ember/object"], function (_exports, _decorators, _getOwner, _ajax, _modalFunctionality, _ajaxError, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    filter: null,
    userList: [],
    type: "going",
    setUserList() {
      this.set("loadingList", true);
      const type = this.get("type");
      const topic = this.get("model.topic");
      let usernames = topic.get(`event.${type}`);
      if (!usernames || !usernames.length) {
        return;
      }
      (0, _ajax.ajax)("/discourse-events/rsvp/users", {
        data: {
          usernames
        }
      }).then(response => {
        let userList = response.users || [];
        this.setProperties({
          userList,
          loadingList: false
        });
      }).catch(e => {
        this.flash((0, _ajaxError.extractError)(e), "alert-error");
      }).finally(() => {
        this.setProperties({
          loadingList: false
        });
      });
    },
    goingNavClass(type) {
      return type === "going" ? "active" : "";
    },
    filteredList(userList, filter) {
      if (filter) {
        userList = userList.filter(u => u.username.indexOf(filter) > -1);
      }
      const currentUser = this.get("currentUser");
      if (currentUser) {
        userList.sort(a => {
          if (a.username === currentUser.username) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return userList;
    },
    setType(type) {
      event?.preventDefault();
      this.set("type", type);
    },
    actions: {
      composePrivateMessage(user) {
        const controller = (0, _getOwner.getOwner)(this).lookup("controller:application");
        this.send("closeModal");
        controller.send("composePrivateMessage", user);
      }
    }
  }, [["method", "setUserList", [(0, _decorators.observes)("type", "model.topic")]], ["method", "goingNavClass", [(0, _decorators.default)("type")]], ["method", "filteredList", [(0, _decorators.default)("userList", "filter")]], ["method", "setType", [_object.action]]]));
});