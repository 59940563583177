define("discourse/plugins/discourse-events/discourse/controllers/admin-events-event", ["exports", "@ember/controller", "@ember/object/computed", "discourse/lib/show-modal", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/mixins/message", "@ember/array"], function (_exports, _controller, _computed, _showModal, _decorators, _message, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, dt7948.p({
    hasEvents: (0, _computed.notEmpty)("events"),
    selectedEvents: (0, _array.A)(),
    selectAll: false,
    order: null,
    asc: null,
    view: "event",
    deleteDisabled(selectedEvents, hasEvents) {
      return !hasEvents || !selectedEvents.length;
    },
    selectDisabled(hasEvents) {
      return !hasEvents;
    },
    actions: {
      showSelect() {
        this.toggleProperty("showSelect");
        if (!this.showSelect) {
          this.setProperties({
            selectedEvents: (0, _array.A)(),
            selectAll: false
          });
        }
      },
      modifySelection(events, checked) {
        if (checked) {
          this.get("selectedEvents").pushObjects(events);
        } else {
          this.get("selectedEvents").removeObjects(events);
        }
      },
      openDelete() {
        var _this = this;
        const modal = (0, _showModal.default)("events-confirm-event-deletion", {
          model: {
            events: this.selectedEvents
          }
        });
        modal.setProperties({
          onDestroyEvents: function () {
            let destroyedEvents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            let destroyedTopicsEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            if (destroyedEvents) {
              _this.get("events").removeObjects(destroyedEvents);
            }
            if (destroyedTopicsEvents) {
              const destroyedTopicsEventIds = destroyedTopicsEvents.map(e => e.id);
              _this.get("events").forEach(event => {
                if (destroyedTopicsEventIds.includes(event.id)) {
                  event.set("topics", null);
                }
              });
            }
          },
          onCloseModal: () => {
            this.send("showSelect");
          }
        });
      }
    }
  }, [["method", "deleteDisabled", [(0, _decorators.default)("selectedEvents.[]", "hasEvents")]], ["method", "selectDisabled", [(0, _decorators.default)("hasEvents")]]]));
});