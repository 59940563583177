define("discourse/plugins/discourse-events/discourse/components/add-event-controls", ["exports", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/components/modal/add-event", "@ember/component", "@ember/service"], function (_exports, _dateUtilities, _decorators, _addEvent, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["event-label"],
    modal: (0, _service.service)(),
    didInsertElement() {
      $(".title-and-category").toggleClass("event-add-no-text", this.get("iconOnly"));
    },
    valueClasses(noText) {
      let classes = "add-event";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    },
    valueLabel(event) {
      return (0, _dateUtilities.eventLabel)(event, {
        noText: this.get("noText"),
        useEventTimezone: true,
        showRsvp: true,
        siteSettings: this.siteSettings
      });
    },
    iconOnly(category, noText) {
      return noText || this.siteSettings.events_event_label_no_text || Boolean(category && category.get("custom_fields.events_event_label_no_text"));
    },
    actions: {
      showAddEvent() {
        this.modal.show(_addEvent.default, {
          model: {
            bufferedEvent: this.event,
            event: this.event,
            update: event => {
              this.set("event", event);
            }
          }
        });
      },
      removeEvent() {
        this.set("event", null);
      }
    }
  }, [["method", "valueClasses", [(0, _decorators.default)("noText")]], ["method", "valueLabel", [(0, _decorators.default)("event")]], ["method", "iconOnly", [(0, _decorators.default)("category", "noText")]]]));
});