define("discourse/plugins/discourse-events/discourse/controllers/events-connection-filters", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/models/connection-filter", "@ember/array"], function (_exports, _controller, _modalFunctionality, _decorators, _connectionFilter, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const QUERY_COLUMNS = [{
    name: "Event Name",
    id: "name"
  }];
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    queryColumns() {
      return QUERY_COLUMNS;
    },
    onShow() {
      if (!this.model.connection.filters) {
        this.model.connection.set("filters", (0, _array.A)());
      }
    },
    actions: {
      addFilter() {
        const filter = _connectionFilter.default.create({
          id: "new"
        });
        this.model.connection.get("filters").pushObject(filter);
      },
      removeFilter(filter) {
        this.model.connection.get("filters").removeObject(filter);
      }
    }
  }, [["method", "queryColumns", [_decorators.default]]]));
});