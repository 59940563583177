define("discourse/plugins/discourse-events/discourse/components/events-header", ["exports", "@ember/component", "I18n", "discourse-common/utils/decorators"], function (_exports, _component, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["events-header"],
    title(view) {
      return _I18n.default.t(`admin.events.${view}.title`);
    }
  }, [["method", "title", [(0, _decorators.default)("view")]]]));
});