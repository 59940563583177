define("discourse/plugins/discourse-events/discourse/components/events-message", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "I18n"], function (_exports, _decorators, _computed, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const icons = {
    error: "times-circle",
    success: "check-circle",
    warn: "exclamation-circle",
    info: "info-circle"
  };
  const urls = {
    provider: "https://discourse.pluginmanager.org/t/539",
    source: "https://discourse.pluginmanager.org/t/540",
    connection: "https://discourse.pluginmanager.org/t/541",
    event: "https://discourse.pluginmanager.org/t/543",
    log: "https://discourse.pluginmanager.org/t/543"
  };
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":events-message", "message.type", "loading"],
    showDocumentation: (0, _computed.not)("loading"),
    showIcon: (0, _computed.not)("loading"),
    hasItems: (0, _computed.notEmpty)("items"),
    icon(type) {
      return icons[type] || "info-circle";
    },
    text(key, view, opts) {
      return _I18n.default.t(`admin.events.message.${view}.${key}`, opts || {});
    },
    documentation() {
      return _I18n.default.t(`admin.events.message.documentation`);
    },
    documentationUrl(view) {
      return urls[view] || "https://discourse.pluginmanager.org/c/discourse-events";
    }
  }, [["method", "icon", [(0, _decorators.default)("message.type")]], ["method", "text", [(0, _decorators.default)("message.key", "view", "message.opts")]], ["method", "documentation", [_decorators.default]], ["method", "documentationUrl", [(0, _decorators.default)("view")]]]));
});