define("discourse/plugins/discourse-events/discourse/components/event-rsvp", ["exports", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/lib/show-modal", "discourse/lib/ajax", "@ember/component", "@ember/object/computed", "I18n", "@ember/object"], function (_exports, _ajaxError, _decorators, _showModal, _ajax, _component, _computed, _I18n, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "event-rsvp",
    goingSaving: false,
    didReceiveAttrs() {
      const currentUser = this.currentUser;
      const eventGoing = this.topic.event.going;
      this.setProperties({
        goingTotal: eventGoing ? eventGoing.length : 0,
        userGoing: currentUser && eventGoing && eventGoing.indexOf(currentUser.username) > -1
      });
    },
    goingClasses(userGoing) {
      return userGoing ? "btn-primary" : "";
    },
    canGo(currentUser, eventFull) {
      return currentUser && !eventFull;
    },
    hasGuests: (0, _computed.gt)("goingTotal", 0),
    hasMax: (0, _computed.notEmpty)("topic.event.going_max"),
    spotsLeft(goingTotal, goingMax) {
      return Number(goingMax) - Number(goingTotal);
    },
    eventFull: (0, _computed.equal)("spotsLeft", 0),
    goingMessage(hasMax, full) {
      if (hasMax) {
        if (full) {
          return _I18n.default.t("event_rsvp.going.max_reached");
        } else {
          const spotsLeft = this.get("spotsLeft");
          if (spotsLeft === 1) {
            return _I18n.default.t("event_rsvp.going.one_spot_left");
          } else {
            return _I18n.default.t("event_rsvp.going.x_spots_left", {
              spotsLeft
            });
          }
        }
      }
      return false;
    },
    updateTopic(userName, _action, type) {
      let existing = this.get(`topic.event.${type}`);
      let list = existing ? existing : [];
      let userGoing = _action === "add";
      if (userGoing) {
        list.push(userName);
      } else {
        list.splice(list.indexOf(userName), 1);
      }
      let props = {
        userGoing,
        goingTotal: list.length
      };
      props[`topic.event.${type}`] = list;
      this.setProperties(props);
    },
    save(user, _action, type) {
      this.set(`${type}Saving`, true);
      (0, _ajax.ajax)(`/discourse-events/rsvp/${_action}`, {
        type: "POST",
        data: {
          topic_id: this.get("topic.id"),
          type,
          usernames: [user.username]
        }
      }).then(result => {
        if (result.success) {
          this.updateTopic(user.username, _action, type);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set(`${type}Saving`, false);
      });
    },
    openModal() {
      event?.preventDefault();
      (0, _showModal.default)("event-rsvp", {
        model: {
          topic: this.get("topic")
        }
      });
    },
    actions: {
      going() {
        const currentUser = this.get("currentUser");
        const userGoing = this.get("userGoing");
        let _action = userGoing ? "remove" : "add";
        this.save(currentUser, _action, "going");
      }
    }
  }, [["method", "goingClasses", [(0, _decorators.default)("userGoing")]], ["method", "canGo", [(0, _decorators.default)("currentUser", "eventFull")]], ["method", "spotsLeft", [(0, _decorators.default)("goingTotal", "topic.event.going_max")]], ["method", "goingMessage", [(0, _decorators.default)("hasMax", "eventFull")]], ["method", "openModal", [_object.action]]]));
});